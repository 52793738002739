/*
 * @Author: zhaohui
 * @Date: 2021-04-29 19:55:57
 * @LastEditTime: 2021-06-17 10:04:11
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/views/send/index.tsx
 */
import { defineComponent } from 'vue';
import {
    Cell,
    Field,
    CellGroup,
    Row,
    RadioGroup,
    Radio,
    Button,
    Notify,
    Dialog,
    Icon
} from 'vant';
import './style.less';
import api_buy, { GetMoney, Pay, CreateOrder } from '@/service/Buy';
import store from '@/store';
import queryString from 'query-string';
interface DataInterface {
    model: string;
    payInfo: {
        visible: boolean;
    };
    getMonsyPara: GetMoney;
    payParams: Pay;
    createOrderParams: CreateOrder;
    btnDisabled: boolean;
}
export default defineComponent({
    data(): DataInterface {
        return {
            model: '',
            payInfo: {
                visible: true
            },
            getMonsyPara: {
                userId: store.state.user.id,
                fileId: ''
            },
            payParams: {
                price: 0,
                studentId: store.state.user.id,
                studentName: store.state.user.name,
                email: '',
                orderNo: '',
                schoolId: store.state.user.schoolId,
                free: false
            },
            createOrderParams: {
                email: '',
                price: '',
                schoolId: '',
                studentId: '',
                studentName: '',
                fileId: '',
                userIp: '',
                free: false
            },
            btnDisabled: false
        };
    },
    methods: {
        async cellClicK() {
            if (!this.$data.payParams.email) {
                Notify('请输入邮箱');
                return;
            }
            try {
                Object.assign(
                    this.$data.createOrderParams,
                    this.$data.payParams
                );
                localStorage.payParams = JSON.stringify(this.$data.payParams);
                this.$data.createOrderParams.userIp = JSON.parse(
                    sessionStorage.location
                ).cip;
                let { data } = await api_buy.createOrder(
                    this.$data.createOrderParams
                );
                this.$data.payParams.orderNo = data.orderNo;
                if (!this.$data.payParams.price) {
                    localStorage.removeItem('payParams');
                    this.$data.btnDisabled = true;
                    await api_buy.pay(this.$data.payParams);
                    this.$router.replace('/order');
                } else {
                    window.location.href = data.payUrl;
                }
            } catch (error) {
                console.log(error);
            }
        },
        valueChange(value: string) {
            this.$data.payParams.email = value;
        },
        async getMoney() {
            try {
                let search: any = queryString.parse(window.location.search);
                this.$data.getMonsyPara.fileId = search.fileId;
                this.$data.createOrderParams.fileId = search.fileId;
                let { data } = await api_buy.getMoney(this.$data.getMonsyPara);
                this.$data.btnDisabled = !data.canBuy;
                this.$data.payParams.free = data.free;
                this.$data.payParams.price = data.free ? 0 : data.price || 0;
                localStorage.removeItem('payParams');
            } catch (error) {
                console.log(error);
            }
        },
        showPayResult(orderNo: string) {
            const that = this;
            Dialog.alert({
                title: '',
                message: () => (
                    <div>
                        <Icon name="wechat-pay" size="24" />
                        <div class="fs24">点击查看支付状态</div>
                    </div>
                ),
                theme: 'round-button'
            }).then(async () => {
                try {
                    let { data } = await api_buy.queryOrder(orderNo);
                    that.$data.payParams.orderNo = orderNo;
                    localStorage.removeItem('payParams');
                    if (data.trade_state === 'SUCCESS') {
                        await api_buy.pay(that.$data.payParams);
                    }
                    that.$router.push('/order');
                } catch (e) {
                    that.$router.push('/order');
                }
            });
        }
    },
    mounted() {
        let search: any = queryString.parse(window.location.search);
        if (search.order) {
            this.showPayResult(search.order);
        } else {
            this.getMoney();
        }
        if (localStorage.payParams) {
            let payParams = JSON.parse(localStorage.payParams);
            this.$data.payParams = payParams;
            this.getMoney();
        }
    },
    render() {
        return (
            <div class="p-2">
                <Cell
                    class="fwb"
                    title="第一步：邮箱填写（建议填写非QQ邮箱）"
                ></Cell>
                <Field
                    modelValue={this.$data.payParams.email}
                    label="邮箱地址"
                    placeholder="在此输入邮箱地址"
                    type="text"
                    {...{
                        onInput: (e: any) => this.valueChange(e.target.value)
                    }}
                ></Field>
                <CellGroup>
                    <Cell class="fwb" title="第二步：费用扣除"></Cell>
                    <Cell title="网络资源服务费" value="1份"></Cell>
                    <Row
                        style={{ minHeight: '100px' }}
                        align="center"
                        justify="center"
                    >
                        <span class="fs24">
                            {this.$data.payParams && this.$data.payParams.price
                                ? this.$data.payParams.price / 100 + '元'
                                : '免费'}
                        </span>
                    </Row>
                </CellGroup>
                <RadioGroup modelValue="1">
                    <CellGroup>
                        <div>
                            <Cell
                                icon="wechat-pay"
                                title="微信支付"
                                clickable={true}
                            >
                                <slot name="right-icon">
                                    <Row justify="end">
                                        <Radio name="1"></Radio>
                                    </Row>
                                </slot>
                            </Cell>
                        </div>
                    </CellGroup>
                </RadioGroup>
                <div class="bottom-bar">
                    <Button
                        {...{
                            onclick: () => this.cellClicK()
                        }}
                        disabled={this.$data.btnDisabled}
                        type="primary"
                        size="large"
                        class="m-b-2"
                    >
                        确认支付
                    </Button>

                    <Button
                        disabled={this.$data.btnDisabled}
                        to="/"
                        type="primary"
                        size="large"
                    >
                        取消
                    </Button>
                </div>
            </div>
        );
    }
});
