/*
 * @Author: zhaohui
 * @Date: 2021-05-06 15:17:11
 * @LastEditTime: 2021-06-17 09:59:07
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/service/Buy.ts
 */
import request from '@/utils/request';
export interface GetMoney {
    userId: string;
    fileId: string;
}
export interface Pay {
    studentId: string;
    schoolId: string;
    email: string;
    studentName: string;
    price: number;
    orderNo: string;
    free: boolean;
}
export interface CreateOrder {
    email: string;
    price: string;
    schoolId: string;
    studentId: string;
    studentName: string;
    fileId: string;
    userIp: string;
    free: boolean;
}
export interface OrderList {
    id: string;
    page: number;
    size: number;
}
export default {
    getMoney(params: GetMoney) {
        return request.post('/user/getMoney', params);
    },
    fileList(userId: string, fileTypeId: string) {
        return request.post('/user/fileList', { userId, fileTypeId });
    },
    filePreview(fileId: string) {
        return request.post('/user/tManngerSignPdf/readPdf', { id: fileId });
    },
    pay(params: Pay) {
        return request.post('/user/paySuccess', params);
    },
    createOrder(params: CreateOrder) {
        return request.post('/user/createOrder', params);
    },
    fileTypelist(userId: string) {
        return request.get(`/user/fileTypeList?userId=${userId}`);
    },
    orderList(params: OrderList) {
        return request.post('/user/orderList', params)
    },
    queryOrder(orderNo:string) {
        return request.get(`/user/queryOrder?orderNo=${orderNo}`);
    },
    fileWaterFal(fileId: string) {
        return request.get(`/user/getData?fileId=${fileId}`)
    }
};
