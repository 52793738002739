/*
 * @Author: zhaohui
 * @Date: 2021-04-29 14:18:16
 * @LastEditTime: 2021-05-23 15:02:55
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/main.ts
 */
import { createApp } from 'vue';
import App from '@/App';
import router from './router';
import store from './store';
import 'vant/lib/index.css';
import '@/assets/style/base.less';
if (localStorage.user && localStorage.token) {
    let user = JSON.parse(localStorage.user);
    let token = JSON.parse(localStorage.token);
    store.commit('UPDATE_USER', user);
    store.commit('UPDATE_TOKEN', token);
} else {
    router.replace('/login');
}
router.beforeEach((to, from, next) => {
    if (!localStorage.user && to.name !== 'login') {
        next('/login');
    } else {
        if (to.name !== 'my') {
            store.commit('UPDATE_MENU_ACTIVE', 1);
        } else {
            store.commit('UPDATE_MENU_ACTIVE', 2);
        }
        next();
    }
});

createApp(App)
    .use(store)
    .use(router)
    .mount('#root');
