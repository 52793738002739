/*
 * @Author: zhaohui
 * @Date: 2021-04-29 19:20:08
 * @LastEditTime: 2021-06-02 21:19:21
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/views/preview-pdf/index.tsx
 */
import { defineComponent } from 'vue';
import {
    Row,
    Col,
    Button,
    Loading,
    Swipe,
    SwipeItem,
    Image as VantImg,
    // ImagePreview
} from 'vant';
import queryString from 'query-string';
import api_buy from '@/service/Buy';
const pdfjs = require('pdfjs-dist');
// import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
interface DataInterface {
    previewData: string;
    search: any;
    preview: boolean;
    imgBase64: any[];
}
export default defineComponent({
    data(): DataInterface {
        return {
            previewData: '',
            search: {},
            preview: false,
            imgBase64: []
        };
    },
    mounted() {
        this.getWaterFal();
    },
    methods: {
        async getWaterFal() {
            let search: any = queryString.parse(window.location.search);
            let { data } = await api_buy.fileWaterFal(search.fileId);
            this.getPreviewFile(data);
        },
        getPreviewFile(text: string) {
            let search: any = queryString.parse(window.location.search);
            this.$data.search = search;
            this.$data.preview = true;
            this.previewPdf(
                `/user/tManngerSignPdf/readPdf?id=${search.fileId}`,
                text
            );
        },
        previewPdf(pdf_url: string, text: string) {
            const that = this;
            pdfjs
                .getDocument(pdf_url)
                .promise.then(function(pdf: any) {
                    for (let i = 1; i <= pdf.numPages; i++) {
                        that.renderPage(pdf, i);
                    }
                    setTimeout(() => {
                        that.createCavansImg(text);
                    }, 1000);
                })
                .finally((e: any) => {
                    console.log(e);
                });
        },
        createCavansImg(text: string = '测试水印') {
            this.$nextTick(() => {
                let container: any = this.$refs.pdfContainer;
                if (container) {
                    container.childNodes.forEach((d: any) => {
                        let ctx = d.getContext('2d');
                        ctx.fillStyle = '#FFC82C';
                        ctx.textAlign = 'center';
                        ctx.rotate((-25 * Math.PI) / 180);
                        ctx.font = '800 30px Microsoft JhengHei';
                        ctx.fillStyle = 'rgba(0,0,0,.2)';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'Middle';
                        // 动态改变字体大小
                        if (ctx.measureText(text).width > 180) {
                            var size = 180 / text.length;
                            ctx.font =
                                '800 ' + size + 'px ' + ' Microsoft JhengHei';
                        }
                        for (
                            let i = document.body.offsetHeight * 0.5 * -1;
                            i < 1800;
                            i += 300
                        ) {
                            for (
                                let j = 0;
                                j < document.body.offsetHeight * 1.5;
                                j += 200
                            ) {
                                // 填充文字，x 间距, y 间距
                                ctx.fillText(text, i, j);
                            }
                        }

                        this.$data.imgBase64.push(d.toDataURL('image/png'));
                    });
                    this.$data.preview = false;
                }
            });
        },
        renderPage(pdfDoc: any, num: number) {
            const that = this;
            pdfDoc.getPage(num).then(function(page: any) {
                var viewport = page.getViewport({ scale: 2 });
                var canvas = that.createPage();
                var ctx = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                page.render({
                    canvasContext: ctx,
                    viewport: viewport
                });
            });
        },
        createPage() {
            const div: any = document.createElement('canvas');
            div.id = 'cavans';
            const container: any = this.$refs.pdfContainer;
            container && container.appendChild(div);
            return div;
        },
        imgClick(src: string) {
            // ImagePreview({
            //     images: this.$data.imgBase64,
            //     closeable: true,
            //     maxZoom: 1
            // });
        }
    },
    render() {
        return (
            <Row
                align="center"
                justify="center"
                style={{
                    width: '100vw',
                    height: '100vh',
                    boxSizing: 'border-box',
                    paddingBottom: '50px'
                }}
            >
                <div
                    ref="pdfContainer"
                    style={{ position: 'relative', zIndex: 1, display: 'none' }}
                ></div>
                <Col>
                    <Loading
                        style={{
                            display: this.$data.preview ? 'flex' : 'none'
                        }}
                    >
                        渲染中请稍候
                    </Loading>
                    <Swipe
                        style={{
                            display: this.$data.preview
                                ? 'none'
                                : 'inline-block'
                        }}
                    >
                        {this.$data.imgBase64.map((item: string) => (
                            <SwipeItem
                                {...{
                                    onClick: () => this.imgClick(item)
                                }}
                            >
                                <VantImg src={item}></VantImg>
                            </SwipeItem>
                        ))}
                    </Swipe>
                </Col>
                <Col class="text-center p-5" span="24">
                    <Button
                        to={`/send-email?fileId=${this.$data.search.fileId}`}
                        type="primary"
                        disabled={this.$data.preview}
                        style={{
                            position: 'fixed',
                            bottom: '50px',
                            zIndex: 2,
                            left: 0,
                            width: '100%'
                        }}
                    >
                        去支付
                    </Button>
                </Col>
            </Row>
        );
    }
});
