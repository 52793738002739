/*
 * @Author: zhaohui
 * @Date: 2021-04-29 19:20:08
 * @LastEditTime: 2021-06-03 23:52:36
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/views/files/index.tsx
 */
import { defineComponent } from 'vue';
import { List, Cell } from 'vant';
import store from '@/store';
import queryString from 'query-string';
import api_buy from '@/service/Buy';
interface DataInterface {
    dataList: any[];
    fileTypeId: string;
}
export default defineComponent({
    data(): DataInterface {
        return {
            dataList: [],
            fileTypeId: ''
        };
    },
    mounted() {
        let search: any = queryString.parse(window.location.search);
        this.$data.fileTypeId = search.fileTypeId;
        this.loadData();
    },
    methods: {
        async loadData() {
            try {
                let { data } = await api_buy.fileList(
                    store.state.user.id,
                    this.$data.fileTypeId
                );
                this.$data.dataList = data ? data.filter((item:any) => item.fileTypeId === this.$data.fileTypeId) : []
            } catch (error) {
                console.log(error);
            }
        }
    },
    render() {
        return (
            <div class="p-l-3 p-r-3">
                <Cell title="文件列表（点击购买）" class="fwb"></Cell>
                <List>
                    {this.$data.dataList.map((item: any) => {
                        return (
                            <Cell
                                title={item.fileName}
                                label={item.fileTypeName}
                                to={`/preview-pdf?fileId=${item.id}`}
                                clickable={true}
                            ></Cell>
                        );
                    })}
                </List>
            </div>
        );
    }
});
