/*
 * @Author: zhaohui
 * @Date: 2021-04-29 14:18:16
 * @LastEditTime: 2021-06-17 17:35:55
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/App.tsx
 */
import Vue, { defineComponent } from 'vue';
import { RouterView } from 'vue-router';
import { Tabbar, TabbarItem } from 'vant';
import store from '@/store';

export default defineComponent({
    name: 'APP',
    data() {
        return {
            menuActive: store.state.menuActive
        };
    },
    methods: {},
    mounted() {},
    render() {
        let bottomBar: Vue.RendererNode | any;
        if (store.state.user.id) {
            bottomBar = (
                <Tabbar fixed={true} modelValue={store.state.menuActive}>
                    <TabbarItem name={1} icon="home-o" to="/">
                        首页
                    </TabbarItem>
                    <TabbarItem name={2} icon="friends-o" to="/my">
                        个人中心
                    </TabbarItem>
                </Tabbar>
            );
        } else {
            bottomBar = '';
        }

        return (
            <div>
                <div style={{ paddingBottom: bottomBar ? '50px' : 0 }}>
                    <RouterView></RouterView>
                </div>
                {bottomBar}
            </div>
        );
    }
});
