/*
 * @Author: zhaohui
 * @Date: 2021-04-29 19:20:08
 * @LastEditTime: 2021-06-01 14:32:33
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/views/login/index.tsx
 */
import { defineComponent } from 'vue';
import {
    Row,
    Field,
    Button,
    CellGroup,
    Col,
    Notify,
    Toast,
    Cell,
    Picker,
    Popup
} from 'vant';
import api_login, { Login as LoginApi } from '@/service/Login';
import store from '@/store';
import './style.less';
import CryptoJS from 'crypto-js'
interface DataInterface {
    params: LoginApi;
    schoolList: [];
    visible: boolean;
    selectedSchool: any;
    params2: LoginApi;
}
function Encrypt(word:string) {
    var key = CryptoJS.enc.Utf8.parse('GZDEKANGDZCJDAPP')
    var srcs = CryptoJS.enc.Utf8.parse(word)
    var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return encrypted.toString()
  }
export default defineComponent({
    data(): DataInterface {
        return {
            params: {
                account: '',
                password: '',
                schoolId: ''
            },
            params2:{
                account: '',
                password: '',
                schoolId: ''
            },
            schoolList: [],
            visible: false,
            selectedSchool: {}
        };
    },
    mounted() {
        this.getSchoolList();
        if (localStorage.user) {
            this.$router.push('/');
        }
        let c = Encrypt('987654321')
        console.log(c)
    },
    methods: {
        async submit() {
            if (!this.$data.params.schoolId) {
                Notify('请选择学校');
                return;
            }
            if (!this.$data.params.account) {
                Notify('请输入用户名');
                return;
            }
            if (!this.$data.params.password) {
                Notify('请输入密码');
                return;
            }
            this.$data.params2.password=Encrypt(this.$data.params.password)
            this.$data.params2.account=Encrypt(this.$data.params.account)
            this.$data.params2.schoolId=this.$data.params.schoolId
            try {
                let { data, success, message } = await api_login.login2(
                    this.$data.params2
                );
                if (success) {
                    localStorage.user = JSON.stringify(data.data);
                    localStorage.token = JSON.stringify(data.data.token);
                    store.commit('UPDATE_USER', data.data);
                    store.commit('UPDATE_TOKEN', data.token);
                    this.$router.push('/');
                } else {
                    Toast.fail(message);
                }
            } catch (error) {
                console.log(error);
            }
        },
        getValue(str: string, type: number) {
            // 1账户 2密码
            if (type === 1) {
                this.$data.params.account = str;
            } else if (type === 2) {
                this.$data.params.password = str;
            }
        },
        async getSchoolList() {
            try {
                let { data } = await api_login.getSchoolList();
                this.$data.schoolList = data;
                this.$data.selectedSchool = data ? data[0] : {};
                this.$data.params.schoolId = this.$data.selectedSchool.id;
            } catch (error) {
                console.log(error);
            }
        },
        pickerConfirm(e: any) {
            this.$data.selectedSchool = e;
            this.$data.params.schoolId = this.$data.selectedSchool.id;
            this.togglePopup();
        },
        togglePopup() {
            this.$data.visible = !this.$data.visible;
        }
    },
    render() {
        return (
            <Row
                justify="center"
                align="center"
                class="bg"
                style={{ width: '100vw', height: '100vh' }}
            >
                <Col span="23" class="text-center bg-main">
                    <div class="m-b-5 fwb fs30">登录</div>
                    <CellGroup class="login-cell-group">
                        <Cell
                            title="学校"
                            value={
                                this.$data.selectedSchool.name
                                    ? this.$data.selectedSchool.name
                                    : '请选择学校'
                            }
                            {...{
                                onclick: () => this.togglePopup()
                            }}
                        ></Cell>
                        <Field
                            modelValue={this.$data.params.account}
                            placeholder="请输入用户名"
                            label="用户名"
                            {...{
                                onInput: (e: any) =>
                                    this.getValue(e.target.value, 1)
                            }}
                        ></Field>
                        <Field
                            modelValue={this.$data.params.password}
                            placeholder="请输入密码"
                            type="password"
                            label="密码"
                            {...{
                                onInput: (e: any) =>
                                    this.getValue(e.target.value, 2)
                            }}
                        ></Field>
                    </CellGroup>
                    <Button
                        {...{
                            onClick: () => {
                                this.submit();
                            }
                        }}
                        type="primary"
                        class="m-t-3"
                        style={{ width: '100%' }}
                    >
                        登录
                    </Button>
                    <Popup position="bottom" show={this.$data.visible}>
                        <Picker
                            value-key="name"
                            columns={this.$data.schoolList}
                            title="选择学校"
                            default-index={this.$data.schoolList.findIndex(
                                (i: any) =>
                                    i.id === this.$data.selectedSchool.id
                            )}
                            {...{
                                onConfirm: this.pickerConfirm,
                                onCancel: this.togglePopup
                            }}
                        ></Picker>
                    </Popup>
                </Col>
            </Row>
        );
    }
});
