/*
 * @Author: zhaohui
 * @Date: 2021-04-29 14:18:16
 * @LastEditTime: 2021-05-09 22:08:44
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/router/index.ts
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/home';
import PreviewPdf from '@/views/preview-pdf';
import SendEmail from '@/views/send';
import Login from '@/views/login';
import Files from '@/views/files';
import My from '@/views/my';
import Order from '@/views/order';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/preview-pdf',
        name: 'preview-pdf',
        component: PreviewPdf
    },
    {
        path: '/send-email',
        name: 'send-email',
        component: SendEmail
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },{
        path: '/files',
        name: 'files',
        component: Files
    },
    {
        path: '/my',
        name: 'my',
        component: My
    },
    {
        path: '/order',
        name: 'order',
        component: Order
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
