/*
 * @Author: zhaohui
 * @Date: 2021-04-29 14:18:16
 * @LastEditTime: 2021-05-08 14:38:55
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/store/index.ts
 */
import { createStore } from 'vuex';

export default createStore({
    state: {
        user: {
            id: '',
            name: '',
            schoolId: '',
            grade: '',
            account: '',
            schoolName: '',
            phone: ''
        },
        token: '',
        menuActive: 1
    },
    mutations: {
        UPDATE_USER: (state, payLoad) => {
            state.user = payLoad;
        },
        UPDATE_TOKEN: (state, payLoad) => {
            state.token = payLoad;
        },
        UPDATE_MENU_ACTIVE: (state, payLoad) => {
            state.menuActive = payLoad;
        }
    },
    actions: {},
    modules: {}
});
