/*
 * @Author: zhaohui
 * @Date: 2021-05-06 14:40:01
 * @LastEditTime: 2021-06-01 14:08:42
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/service/Login.ts
 */
import request from '@/utils/request';
export interface Login {
    account: string;
    password: string;
    schoolId: string;
}

export default {
    login2(params: Login) {
        return request.post('/user/login2', params);
    },
    login(params: Login) {
        return request.post('/user/login', params);
    },
    logOut(id: string) {
        return request.get(`/user/logout?id=${id}`)
    },
    getSchoolList() {
        return request.get('/user/schoolList');
    }
};
