/*
 * @Author: zhaohui
 * @Date: 2021-04-29 15:32:28
 * @LastEditTime: 2021-06-17 17:32:52
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/views/home/index.tsx
 */
import Vue, { defineComponent } from 'vue';
import { Swipe, SwipeItem, Image, Cell, Row, Col, Icon } from 'vant';
import api_buy from '@/service/Buy';
import store from '@/store';
interface PropsInterface {
    bannerList: BannerItem[];
    bannerConfig: any;
    pannelList: PannelItem[];
}
interface BannerItem {
    name: string;
    src: string;
}
interface PannelItem {
    name: string;
    icon: Vue.RendererNode;
    id: string;
}
export default defineComponent({
    data(): PropsInterface {
        return {
            bannerList: [
                {
                    name: 'banner1',
                    src: require('@/assets/images/banner.png')
                }
            ],
            bannerConfig: {},
            pannelList: []
        };
    },
    methods: {
        linkTo(id: string) {
            this.$router.push(`/files?fileTypeId=${id}`);
        },
        async loadData() {
            try {
                let { data } = await api_buy.fileTypelist(store.state.user.id);
                const icons: string[] = [
                    'todo-list',
                    'manager',
                    'send-gift',
                    'bars'
                ];
                this.$data.pannelList = data
                    ? data.map((item: any, index: number) => {
                          return {
                              name: item.name,
                              id: item.id,
                              icon: (
                                  <Icon class="fs22 color-deep-blue" name={icons[index]}></Icon>
                              )
                          };
                      })
                    : [];
            } catch (error) {
                console.log(error);
            }
        }
    },
    mounted() {
        this.loadData();
    },
    render() {
        return (
            <div>
                <Swipe {...this.$data.bannerConfig}>
                    {this.$data.bannerList.map((item: BannerItem) => (
                        <SwipeItem>
                            <Image src={item.src}></Image>
                        </SwipeItem>
                    ))}
                </Swipe>
                <Cell title="协能办事" class="fwb"></Cell>
                <Row align="center" justify="start" class="p-2">
                    {this.$data.pannelList.map((item: PannelItem) => (
                        <Col span="6" class="text-center">
                            <div onClick={() => this.linkTo(item.id)}>
                                <div>{item.icon}</div>
                                <div class="fs12 color-deep-grey">{item.name}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
});
