/*
 * @Author: zhaohui
 * @Date: 2021-04-29 19:20:08
 * @LastEditTime: 2021-06-17 10:01:11
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/views/order/index.tsx
 */
import { defineComponent } from 'vue';
import { List, Cell, CellGroup, Sticky, Row, Col, Toast, Button } from 'vant';
import store from '@/store';
import Dayjs from 'dayjs';
import api_buy, { OrderList, Pay } from '@/service/Buy';
interface DataInterface {
    dataList: any[];
    params: OrderList;
    finished: boolean;
    loading: boolean;
    payParams: Pay;
}
export default defineComponent({
    data(): DataInterface {
        return {
            dataList: [],
            params: {
                page: 1,
                size: 10,
                id: store.state.user.id
            },
            loading: false,
            finished: false,
            payParams: {
                price: 0,
                studentId: '',
                studentName: '',
                email: '',
                orderNo: '',
                schoolId: store.state.user.schoolId,
                free: false
            }
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async download(item: any) {
            window.open('user/tManngerSignPdf/downloadPdf?fileId=' + item.fileId + '&orderNo=' + item.orderNo)
        },
        getDownloadTime(date: number) {
            let today = new Date().getTime();
            let long = (today - date) / (24 * 60 * 60 * 1000);
            return long;
        },
        async loadData() {
            try {
                this.$data.loading = true;
                let { data } = await api_buy.orderList(this.$data.params);
                this.$data.loading = false;
                let { records, total } = data;
                this.$data.dataList = [...this.$data.dataList, ...records];
                if (this.$data.dataList.length >= total) {
                    this.$data.finished = true;
                } else {
                    this.$data.params.page += 1;
                }
            } catch (error) {
                this.$data.finished = true;
                console.log(error);
            }
        },
        async resend(item: any) {
            try {
                this.payParams.price = item.price;
                this.payParams.studentId = item.studentId;
                this.payParams.studentName = item.studentName;
                this.payParams.email = item.email;
                this.payParams.orderNo = item.orderNo;
                this.payParams.schoolId = item.schoolId;
                await api_buy.pay(this.$data.payParams);
                Toast.success('已重新发送');
            } catch (error) {
                Toast.fail('发送失败');
            }
        }
    },
    render() {
        return (
            <div
                class="p-l-3 p-r-3"
                style={{
                    overflow: 'inherf'
                }}
            >
                <Sticky>
                    <Cell title="订单列表" class="fwb"></Cell>
                </Sticky>
                <List
                    finished={this.$data.finished}
                    loading={this.$data.loading}
                    finished-text="没有更多了"
                    {...{
                        onLoad: () => this.loadData()
                    }}
                >
                    <CellGroup>
                        {this.$data.dataList.map((item: any) => (
                            <Cell
                                title={`邮箱：${item.email}`}
                                v-slots={{
                                    label: () => (
                                        <Row>
                                            <Col span={15}>
                                                <div>
                                                    订单号：{item.orderNo}
                                                </div>
                                                <div>
                                                    支付时间：
                                                    {item.payDate
                                                        ? Dayjs(
                                                            item.payDate
                                                        ).format(
                                                            'YYYY/MM/DD HH:mm:ss'
                                                        )
                                                        : ''}
                                                </div>
                                                <div>
                                                    下单时间：{' '}
                                                    {item.orderDate
                                                        ? Dayjs(
                                                            item.orderDate
                                                        ).format(
                                                            'YYYY/MM/DD HH:mm:ss'
                                                        )
                                                        : ''}
                                                </div>
                                            </Col>
                                        </Row>
                                    ),
                                    default: () => {

                                        return (
                                            <Row justify="end" style="height:100%;">
                                                <Col span={24}>
                                                    <div>
                                                        支付状态：
                                                        <span
                                                            class={
                                                                item.paystatus ===
                                                                    2
                                                                    ? 'color-green'
                                                                    : 'color-red'
                                                            }
                                                        >
                                                            {item.paystatus ===
                                                                2
                                                                ? '支付成功'
                                                                : '待支付'}
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col span={24}>
                                                    <Button
                                                        {...{
                                                            onClick: () => {
                                                                this.download(item);
                                                            }
                                                        }}
                                                        style={{
                                                            'display': item.paystatus === 2 && this.getDownloadTime(item.payDate) < 3 ? 'inline-block' : 'none'
                                                        }}
                                                        size="small"
                                                        type="primary">
                                                        下载
                                                    </Button>
                                                </Col>
                                            </Row>
                                        );
                                    }
                                }}
                            ></Cell>
                        ))}
                    </CellGroup>
                </List>
            </div>
        );
    }
});
