/*
 * @Author: zhaohui
 * @Date: 2021-04-29 19:20:08
 * @LastEditTime: 2021-05-24 09:34:01
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/views/my/index.tsx
 */
import { defineComponent } from 'vue';
import { Image, CellGroup, Cell } from 'vant';
import './style.less';
import store from '@/store';

interface DataInterface {
    params: {};
}
export default defineComponent({
    data(): DataInterface {
        return {
            params: {
                account: '',
                password: ''
            }
        };
    },
    mounted() {},
    methods: {
        logOut() {
            window.localStorage.clear();
            this.$router.replace('/login');
        }
    },
    render() {
        return (
            <div>
                <div class="top">
                    <Image
                        fit="fill"
                        src={require('@/assets/images/school.jpeg')}
                    ></Image>
                </div>
                <CellGroup>
                    <Cell
                        title="学校名称"
                        value={store.state.user.schoolName}
                    ></Cell>
                    <Cell title="年级" value={store.state.user.grade}></Cell>
                    <Cell title="姓名" value={store.state.user.name}></Cell>
                    <Cell title="手机号" value={store.state.user.phone}></Cell>
                    <Cell
                        title="订单记录"
                        is-link
                        clickable={true}
                        to={'/order'}
                    ></Cell>
                    <Cell
                        title="退出登录"
                        is-link
                        clickable={true}
                        {...{
                            onClick: () => this.logOut()
                        }}
                    ></Cell>
                </CellGroup>
            </div>
        );
    }
});
