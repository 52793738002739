/*
 * @Author: zhaohui
 * @Date: 2020-11-06 18:48:43
 * @LastEditTime: 2021-06-01 15:29:31
 * @LastEditors: zhaohui
 * @Description:
 * @FilePath: /education-user/src/utils/request.ts
 */
import axios from 'axios';
import store from '../store';
// import Router from '../router';
import { Notify } from 'vant';

const request: any = axios.create({
    baseURL: ''
});

request.interceptors.request.use(
    function(config: any) {
        // Do something before request is sent
        const stateData: any = store.state;
        if (stateData.user && stateData.token) {
            config['headers']['token'] = stateData.token;
        }
        return config;
    },
    function(error: any) {
        // Do something with request error
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    function(response: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        let {message, success } = response.data;
        if (success) {
            return response.data;
        } else {
            Notify(message || '出错咯');
            return Promise.reject(response.data);
        }
        // switch (Number(code)) {
        //     case 200:
        //         return response.data;
        //     case 403:
        //     case 404:
        //     case 422:
        //         Notify(message || '出错咯');
        //         return Promise.reject(response.data);
        //     case 500:
        //     case 503:
        //     case 10000:
        //     case 20000:
        //         Notify(message || '出错咯');
        //         return Promise.reject(response.data);
        //     case 401:
        //         localStorage.clear();
        //         Router.replace('/login');
        //         break;
        //     default:
        //         Notify(message || '出错咯');
        //         return response.data;
        // }
    },
    function(error: any) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);

export default request;
